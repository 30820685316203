.green {
    color: green;
}

.red {
    color: red;
}

td, th {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: rgb(95, 91, 92);
    color: rgb(256, 256, 256);
}

tr:nth-child(even) {
    background-color: rgb(220, 220, 220);
}

table {
    border-collapse: collapse;
    width: 100%;
    max-width: 500px;
}