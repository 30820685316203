.app {
  display: flex;
  height: 100vh;
}

.toggle-buttons {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.toggle-button {
  width: 150px;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
  background-color: rgb(189, 99, 47);
  color: rgb(256, 256, 256);
  border-style: none;
  cursor: pointer;
}

.graph {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}